function toggleLoader(loaderContainer, isLoaderVisible) {
  // if (!isLoaderVisible) {
  //   isLoaderVisible = true;
  // }
  var btn = loaderContainer.find('button');
  var loader = loaderContainer.find('.list-load-more-loader');
  btn.removeClass('is-hidden');
  loader.removeClass('is-hidden');

  if (isLoaderVisible) {
    btn.addClass('is-hidden');
  } else {
    loader.addClass('is-hidden');
  }
}

function prepareEndpoint() {
  var lang = $('html').attr('lang');

  if (lang !== 'en') {
    return 'https://ardigen.com/' + lang + '/wp-json/';
  }

  return 'https://ardigen.com/wp-json/';
}

$(document).ready(function () {
  (function () {
    var section = $('.news .list');
    var listContainer = section.find('.list-of-news');
    var loaderContainer = section.find('.list-load-more'); // var noMoreInfo = section.find('.list-load-more-no-more');

    var moreBtn = section.find('.list-load-more button');
    var loader = section.find('.list-load-more-loader');
    var catListItems = section.find('.list-cats li');
    var catButtons = section.find('.list-cats a');
    moreBtn.on('click', function () {
      var activeCat = section.find('.list-cats .is-active a').data('slug'); // noMoreInfo.addClass('is-hidden');

      toggleLoader(loaderContainer, true);
      var offset = listContainer.find('.c1-list-flex-item').length;
      $.ajax({
        url: prepareEndpoint() + 'content/v1/news?category=' + activeCat + '&offset=' + offset + '&count=6',
        success: function (response) {
          toggleLoader(loaderContainer, false);

          if (response.items.length === 0) {
            moreBtn.addClass('is-hidden');
            return;
          }

          response.items.forEach(function (item) {
            var cont = $('<div class="c1-list-flex-item"></div>');
            cont.append($(item));
            listContainer.append(cont);
          });

          if (response.isMore === false) {
            moreBtn.addClass('is-hidden');
            return;
          }
        },
        error: function (xhr) {
          console.log('err' + xhr.responseText);
        }
      });
    });
    catButtons.on('click', function (e) {
      e.preventDefault();
      catListItems.removeClass('is-active');
      $(this).closest('li').addClass('is-active');
      var slug = $(this).data('slug'); // noMoreInfo.addClass('is-hidden');

      toggleLoader(loaderContainer, true);
      listContainer.empty();
      $.ajax({
        url: prepareEndpoint() + 'content/v1/news?category=' + slug + '&offset=0&count=9',
        success: function (response) {
          toggleLoader(loaderContainer, false);

          if (response.items.length === 0) {
            moreBtn.addClass('is-hidden'); // noMoreInfo.removeClass('is-hidden');

            return;
          }

          response.items.forEach(function (item) {
            var cont = $('<div class="c1-list-flex-item"></div>');
            cont.append($(item));
            listContainer.append(cont);
          });

          if (response.isMore === false) {
            moreBtn.addClass('is-hidden');
            return;
          }
        }
      });
    });
  })();

  (function () {
    //PEOPLE SECTION
    var peopleSection = $('.people .people-boards');
    var peopleBioSection = $('.people #people-bottom-people-bio');
    var peopleBioContainer = peopleBioSection.find('.people-bio-container');
    var peopleBioBtn = peopleSection.find('.people-board-bio-btn');
    var peopleLoader = peopleBioSection.find('.people-bio-loader');
    var closeText = peopleBioContainer.data('close'); //repeted below.

    peopleBioContainer.find('.people-bio-close a').on('click', function () {
      peopleSection.find('.people-board-item.is-active').removeClass('is-active');
      setTimeout(function () {
        peopleBioContainer.empty();
      }, 10);
    });
    peopleBioBtn.on('click', function (e) {
      e.preventDefault();
      var ID = $(this).data('id');
      peopleBioContainer.empty();
      peopleLoader.removeClass('is-hidden');
      $.ajax({
        url: prepareEndpoint() + 'content/v1/people?id=' + ID + '&close=' + closeText,
        success: function (response) {
          peopleBioContainer.append($(response.item));
          peopleBioContainer.find('.people-bio-close a').on('click', function () {
            peopleSection.find('.people-board-item.is-active').removeClass('is-active');
            setTimeout(function () {
              peopleBioContainer.empty();
            }, 10);
          });
          peopleLoader.addClass('is-hidden');
        }
      });
    });
  })();

  (function () {
    //ARTICLE SECTION
    var articleSection = $('.services .article-section');
    var articleContainer = articleSection.find('.article-container');
    var articleLoader = articleSection.find('.article-loader');
    var learnSection = $('.services .learn');
    var learnBtn = learnSection.find('.learn-item .learn-item-btn a');
    var closeText = articleContainer.data('close');
    var backText = articleContainer.data('back');
    learnBtn.on('click', function (e) {
      e.preventDefault();
      var ID = $(this).data('id');
      console.log(ID);
      articleContainer.empty();
      articleLoader.removeClass('is-hidden');
      $.ajax({
        url: prepareEndpoint() + 'content/v1/article?id=' + ID + '&close=' + closeText + '&back=' + backText,
        success: function (response) {
          articleContainer.append($(response.item));
          articleContainer.find('.article-close a').on('click', function (e) {
            setTimeout(function () {
              articleContainer.empty();
            }, 10);
          });
          articleLoader.addClass('is-hidden');
        }
      });
    });
  })();

  (function () {
    //JOBS SECTION
    function onJobDetailsClick(e) {
      var id = $(this).data('id');
      detailContainer.empty();
      loader.removeClass('is-hidden');
      $.ajax({
        url: prepareEndpoint() + 'content/v1/job?id=' + id,
        success: function (response) {
          detailContainer.append($(response.item));
          detailContainer.find('.job-detail-close').on('click', closeCurrentJob);
          detailContainer.find('.job-detail-actions a').on('click', onJoinUsClick);
          loader.addClass('is-hidden');
        }
      });
    }

    function closeCurrentJob(e) {
      setTimeout(function () {
        detailContainer.empty();
      }, 10);
    }

    function onJoinUsClick() {
      //set select form to this one
      var id = $(this).data('id');
      var select = $('.careers .contact form select');
      var value = select.find('option[data-id="' + id + '"]').attr('value');
      select.val(value);
    }

    var section = $('.careers .jobs');
    var listContainer = section.find('.jobs-container .jobs-flex');
    var loaderContainer = section.find('.jobs-more-offers');
    var loader = section.find('.jobs-more-offers-loader'); // var noMoreInfo = section.find('.list-load-more-no-more');

    var moreBtn = section.find('.jobs-more-offers button'); // var detailsBtns = section.find('.jobs-item-more a');

    var catListItems = section.find('.jobs-menu li');
    var catMobileListItems = section.find('.mobile-selector-list li');
    var catMobileListHead = section.find('.c-selector-head span');
    var catButtons = section.find('.jobs-menu a, .mobile-selector-list a');
    var detailContainer = section.find('.job-details .container'); //If job selected on init;

    detailContainer.find('.job-detail-close').on('click', closeCurrentJob);
    detailContainer.find('.job-detail-actions a').on('click', onJoinUsClick); // detailsBtns.on('click', onJobDetailsClick);
    // moreBtn.on('click', function() {
    //   var activeCat = section.find('.jobs-menu li.is-active a').data('slug');
    //   loader.removeClass('is-hidden');
    //   moreBtn.addClass('is-hidden');
    //   var offset = listContainer.find('.jobs-flex-item').length;
    //   $.ajax({
    //     url:
    //       prepareEndpoint() +
    //       'content/v1/jobs?category=' +
    //       activeCat +
    //       '&offset=' +
    //       offset +
    //       '&count=6',
    //     success: function(response) {
    //       loader.addClass('is-hidden');
    //       moreBtn.removeClass('is-hidden');
    //       if (response.items.length === 0) {
    //         // noMoreInfo.removeClass('is-hidden');
    //         moreBtn.addClass('is-hidden');
    //         return;
    //       }
    //       response.items.forEach(function(item) {
    //         var i = $(item);
    //         i.find('.jobs-item-more a').on('click', onJobDetailsClick);
    //         listContainer.append(i);
    //       });
    //       if (response.isMore === false) {
    //         moreBtn.addClass('is-hidden');
    //         return;
    //       }
    //     },
    //   });
    // });

    catButtons.on('click', function (e) {
      e.preventDefault();
      var selectedName;
      var selectedSlug;
      var isMobile = $(this).data('mobile');

      if (isMobile) {
        console.log('mobile');
        selectedName = $(this).data('slug');
        selectedSlug = $(this).data('name');
      } else {
        selectedName = $(this).data('name');
        selectedSlug = $(this).data('slug');
      }

      console.log(selectedSlug, selectedName);
      catMobileListItems.removeClass('is-active');
      catListItems.removeClass('is-active');
      catMobileListItems.find('a[data-slug="' + selectedName + '"]').closest('li').addClass('is-active');
      catListItems.find('a[data-slug="' + selectedSlug + '"]').closest('li').addClass('is-active');

      if (selectedSlug !== '') {
        catMobileListHead.text(selectedName);
      } else {} // noMoreInfo.addClass('is-hidden');


      loader.removeClass('is-hidden');
      moreBtn.addClass('is-hidden');
      listContainer.empty();
      $.ajax({
        url: prepareEndpoint() + 'content/v1/jobs?category=' + selectedSlug + '&offset=0&count=6',
        success: function (response) {
          // if(response.items.length === 0){
          //   moreBtn.addClass('is-hidden');
          //   noMoreInfo.removeClass('is-hidden');
          //   return;
          // };
          response.items.forEach(function (item) {
            // var cont = $('<div class="c1-list-flex-item"></div>');
            // cont.append($(item));
            var i = $(item);
            i.find('.jobs-item-more a').on('click', onJobDetailsClick);
            listContainer.append(i);
          });
          loader.addClass('is-hidden');
          moreBtn.removeClass('is-hidden');

          if (response.isMore === false) {
            moreBtn.addClass('is-hidden');
            return;
          }
        }
      });
    });
  })(); //BLog


  (function () {
    console.log('Running');
    var section = $('.blog .list');
    var listContainer = section.find('.blog-list');
    var loaderContainer = section.find('.list-load-more');
    var moreBtn = section.find('.list-load-more button');
    var loader = section.find('.list-load-more-loader'); //Category fillter function

    var catListItems = section.find('.list-cats li');
    var catButtons = section.find('.list-cats a');
    moreBtn.on('click', function () {
      loader.removeClass('is-hidden');
      moreBtn.addClass('is-hidden');
      var offset = listContainer.find('.c1-list-flex-item').length;
      $.ajax({
        url: prepareEndpoint() + 'content/v1/posts?offset=' + offset + '&count=9',
        success: function (response) {
          loader.addClass('is-hidden');
          moreBtn.removeClass('is-hidden');

          if (response.items.length === 0) {
            moreBtn.addClass('is-hidden');
            return;
          }

          response.items.forEach(function (item) {
            var cont = $('<div class="c1-list-flex-item"></div>');
            cont.append($(item));
            listContainer.append(cont);
          });

          if (response.isMore === false) {
            moreBtn.addClass('is-hidden');
            return;
          }
        }
      });
    });
    catButtons.on('click', function (e) {
      e.preventDefault();
      catListItems.removeClass('is-active');
      $(this).closest('li').addClass('is-active');
      var slug = $(this).data('slug'); // noMoreInfo.addClass('is-hidden');

      toggleLoader(loaderContainer, true);
      listContainer.empty();
      $.ajax({
        url: prepareEndpoint() + 'content/v1/posts?category=' + slug + '&offset=0&count=9',
        success: function (response) {
          toggleLoader(loaderContainer, false);

          if (response.items.length === 0) {
            moreBtn.addClass('is-hidden'); // noMoreInfo.removeClass('is-hidden');

            return;
          }

          response.items.forEach(function (item) {
            var cont = $('<div class="c1-list-flex-item"></div>');
            cont.append($(item));
            listContainer.append(cont);
          });

          if (response.isMore === false) {
            moreBtn.addClass('is-hidden');
            return;
          }
        }
      });
    });
  })();
});